import React from 'react';

import Layout from '../components/layout';

export default function English() {
      return (
            <Layout subject="English">
                  English
            </Layout>
      )
}